import React from "react";
import "./PrivacyPolicy.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  document.title = t("page-title.privacy-policy");

  var lang = i18n.language;

  return (
    <div className="UIPrivacyPolicy">
      <div className="container-fluid background">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h1 className="text-center">{t("privacy-policy.title")}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container content">        
          <div className="text mb-5">
            <p className="text-center">{t("privacy-policy.article-12")}</p>
            <p className="text-center">{t("privacy-policy.date")}</p>
            <p>{t("privacy-policy.intro-1")}</p>
            <p>{t("privacy-policy.intro-2")}</p>
          </div>
          <div className="text mb-5">
            <h5 className="mb-3">
              <strong>
                1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.1-title")}
              </strong>
            </h5>
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.1-p1") }} />
          </div>

          <div className="text mb-5">
            <h5 className="mb-3">
              <strong>
                2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.2-title")}
              </strong>
            </h5>
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.2-p1") }} />                      
            <p className="mb-3">i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.2-i-title")}</p>
            <p className="mb-2">{t("privacy-policy.2-i-p1")}</p>              
            <ul className="mb-5" dangerouslySetInnerHTML={{ __html: t("privacy-policy.2-i-ul") }} />
            <p dangerouslySetInnerHTML={{ __html: t("privacy-policy.2-i-p2") }} />
            <p className="mt-5 mb-3">ii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.2-ii-title")}</p>
            <p className="mb-3">{t("privacy-policy.2-ii-p1")}</p>
            <p className="mb-3">{t("privacy-policy.2-ii-p2")}</p>
            <p className="mb-3">{t("privacy-policy.2-ii-p3")}</p>
            <p className="mb-3">{t("privacy-policy.2-ii-p4")}</p>
            <p className="mb-3">{t("privacy-policy.2-ii-p5")}</p>
            <p className="mt-5 mb-3">iii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.2-iii-title")}</p>
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.2-iii-p1")}}/>           
            <p className="mt-5 mb-3">iv.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.2-iv-title")}</p>
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.2-iv-p1")}}/>
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.2-iv-p2")}}/>
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.2-iv-p3")}}/>
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.2-iv-p4")}}/>            
          </div>

          <div className="text mb-5">
            <h5 className="mb-3">
              <strong>
                3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.3-title")}
              </strong>
            </h5>
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.3-p1") }} />             
            <ul className="mb-5" dangerouslySetInnerHTML={{ __html: t("privacy-policy.3-ul") }} />                        
          </div>

          <div className="text mb-5">
            <h5 className="mb-3">
              <strong>
                4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.4-title")}
              </strong>
            </h5>
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.4-p1") }} />             
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.4-p2") }} />
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.4-p3") }} />
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.4-p4") }} />
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.4-p5") }} />
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.4-p6") }} />
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.4-p7") }} />
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.4-p8") }} />
          
            <table class="table table-bordered table-striped table-hover table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th>{t("privacy-policy.4-table-name")}</th>
                  <th>{t("privacy-policy.4-table-duration")}</th>
                  <th>{t("privacy-policy.4-table-purpose")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("privacy-policy.4-table-ga-name")}</td>
                  <td>{t("privacy-policy.4-table-ga-duration")}</td>
                  <td>{t("privacy-policy.4-table-ga-purpose")}</td>
                </tr>
                <tr>
                  <td>{t("privacy-policy.4-table-ga_*-name")}</td>
                  <td>{t("privacy-policy.4-table-ga_*-duration")}</td>
                  <td>{t("privacy-policy.4-table-ga_*-purpose")}</td>
                </tr>
                <tr>
                  <td>{t("privacy-policy.4-table-cc_cookie-name")}</td>
                  <td>{t("privacy-policy.4-table-cc_cookie-duration")}</td>
                  <td>{t("privacy-policy.4-table-cc_cookie-purpose")}</td>
                </tr>
                <tr>
                  <td>{t("privacy-policy.4-table-langMenu-name")}</td>
                  <td>{t("privacy-policy.4-table-langMenu-duration")}</td>
                  <td>{t("privacy-policy.4-table-langMenu-purpose")}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="text mb-5">
            <h5 className="mb-3">
              <strong>
                5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.5-title")}
              </strong>
            </h5>
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.5-p1") }} />             
            <ul className="mb-5" dangerouslySetInnerHTML={{ __html: t("privacy-policy.5-ul") }} />                        
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.5-p2") }} />
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.5-p3") }} />
          </div>

          <div className="text mb-5">
            <h5 className="mb-3">
              <strong>
                6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.6-title")}
              </strong>
            </h5>
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.6-p1") }} />
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.6-p2") }} />
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.6-p3") }} /> 
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.6-p4") }} /> 
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.6-p5") }} /> 
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.6-p6") }} /> 
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.6-p7") }} /> 
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.6-p8") }} /> 
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.6-p9") }} /> 
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.6-p10") }} />            
          </div>

          <div className="text mb-5">
            <h5 className="mb-3">
              <strong>
                7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.7-title")}
              </strong>
            </h5>
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.7-p1") }} />                       
          </div>

          <div className="text mb-5 text-start">
            <h5 className="mb-3">
              <strong>
                8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.8-title")}
              </strong>
            </h5>
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.8-p1") }} />
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.8-p2") }} />
          </div>

          <div className="text mb-5">
            <h5 className="mb-3">
              <strong>
                9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.9-title")}
              </strong>
            </h5>
            <p className="mb-2" dangerouslySetInnerHTML={{ __html: t("privacy-policy.9-p1") }} />
          </div>

          <div className="text mb-5">
            <h5 className="mb-3">
              <strong>
                10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("privacy-policy.10-title")}
              </strong>
            </h5>
            <p className="mb-3" dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-p1") }} />
            <ul>            
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li2") }} /> 
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li3") }} /> 
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li4") }} /> 
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li5") }} /> 
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li6") }} /> 
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li7") }} /> 
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li8") }} /> 
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li9") }} /> 
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li10") }} /> 
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li11") }} /> 
              <li dangerouslySetInnerHTML={{ __html: t("privacy-policy.10-li12") }} />
            </ul>             
          </div>

      </div>
    </div>
  );
};

export default PrivacyPolicy;
