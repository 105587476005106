import React, { useEffect } from "react";
import "./SupplyHouseholds.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Cardtso from "./Elements/Cardtso";
import Cardcompanies from "./Elements/Cardcompanies";
import Cardstar from "./Elements/Cardstar";
import VideoLower from "./Elements/VideoLower";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const SupplyHouseholds = () => {
    const { t } = useTranslation();
    var lang = i18n.language;

    document.title = t("page-title.elsupply");

    return (
        <div className="UISupplyHouseholds">
            <div className="container-fluid background">
                <div className="bg-image">
                    <VideoLower />
                </div>
                <div className="container">
                    <div className="heading">
                        <h2>{t("elsupply.households_title")}</h2>
                        <h1>{t("pages.elsupply")}</h1>
                    </div>
                </div>
            </div>
            <div className="container hero-image">
                <img
                    src={`${process.env.PUBLIC_URL}/images/products/households.png`}
                    alt=""
                    className="starter-whatwedo"
                />
            </div>
            <div className="overlay">
                <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tr.svg`} alt="" />
            </div>

            <div className="container content">
                <div className="heading mb-5">
                    <h4>{t("elsupply.households.packets_subtitle")}</h4>
                    <h3>{t("elsupply.households.packets")}</h3>
                </div>
                <div className="functions">
                    <a href={t('elsupply.url_households')} target="_blank" class="package-link">
                        <div className="function package">
                            <div className="function_title heading">
                                <h4 className="package_title">{t("elsupply.households.packet_title_1")}</h4>
                                <div dangerouslySetInnerHTML={{ __html: t("elsupply.households.packet_text_1") }} class="package_bullets"></div>
                                <p><span class="pricelist_small_title">{t("elsupply.households.el_prices")}:</span>
                                    <ul>
                                        <li>ET: {t("elsupply.households.packet_od")} 0,12980 EUR/kWh</li>
                                    </ul>
                                </p>
                                <small>{t('elsupply.households.no_tax_prices')}</small>
                            </div>
                            <div class="readmore"><NavLink to={t('elsupply.url_households')} target="_blank">{t("elsupply.households.button_1")}</NavLink></div>
                        </div>
                    </a>
                    <a href={t('url.novadobasamooskrbe')} target="_blank" class="package-link">
                        <div className="function package">
                            <div class="ribbon right">
                                {t("elsupply.households.packet_new")}
                                </div>
                            <div className="function_title heading">
                                <h4 className="package_title">{t("elsupply.households.packet_title_2")}</h4>
                                <p className="text-center">{t("elsupply.households.packet_subtitle_2")}</p>
                                <div dangerouslySetInnerHTML={{ __html: t("elsupply.households.packet_text_2") }} class="package_bullets"></div>
                                <p><span class="pricelist_small_title">{t("elsupply.households.el_prices")}:</span>
                                    <ul>
                                        <li>ET: {t("elsupply.households.packet_od")} 0,12980 EUR/kWh</li>
                                    </ul>
                                </p>
                                <small>{t('elsupply.households.no_tax_prices')}</small>
                            </div>
                            <div class="readmore"><NavLink to={t('url.novadobasamooskrbe')} exact>{t("products.readmore")}</NavLink></div>
                        </div>
                    </a>
                    <a href={t('elsupply.url_households')} target="_blank" class="package-link">
                        <div className="function package">
                            <div className="function_title heading">
                                <h4 className="package_title">{t("elsupply.households.packet_title_3")}</h4>
                                <div dangerouslySetInnerHTML={{ __html: t("elsupply.households.packet_text_3") }} class="package_bullets"></div>
                                <p><span class="pricelist_small_title">{t("elsupply.households.el_prices")}:</span>
                                    <ul>
                                        <li>VT: {t("elsupply.households.packet_od")} 0,11958 EUR/kWh</li>
                                        <li>MT: {t("elsupply.households.packet_od")} 0,09800 EUR/kWh</li>
                                        <li>ET: {t("elsupply.households.packet_od")} 0,10880 EUR/kWh</li>
                                    </ul>
                                </p>
                                <small>{t('elsupply.households.no_tax_prices')}</small>
                            </div>
                            <div class="readmore"><NavLink to={t('elsupply.url_households')} target="_blank">{t("elsupply.households.button_1")}</NavLink></div>
                        </div>
                    </a>
                </div>
            </div>

            <div className="container content">
                <div className="text">
                    <p>{t("elsupply.households.text_1")}</p>
                    <NavLink to={t("url.contact")} className="btn-primary" exact>
                        {t("elsupply.households.button_1")}
                    </NavLink>
                </div>
            </div>
            <div className="container content">
                <div className="heading mb-5">
                    <h4>{t("elsupply.households.subtitle_1")}</h4>
                    <h3>{t("elsupply.households.title_1")}</h3>
                </div>
                <div className="text">
                    <p>{t("elsupply.households.text_2")}</p>
                </div>
                <div className="supply_icons">
                    <div className="icon">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/households_1.png`} alt="" className="icon_image" />
                        <div className="icon_title heading">
                            <h4>{t("elsupply.households.icon_title_1")}</h4>
                        </div>
                        <div className="icon_text">
                            <p>{t("elsupply.households.icon_text_1")}</p>
                        </div>
                    </div>
                    <div className="icon">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/households_2.png`} alt="" className="icon_image" />
                        <div className="icon_title heading">
                            <h4>{t("elsupply.households.icon_title_2")}</h4>
                        </div>
                        <div className="icon_text">
                            <p>{t("elsupply.households.icon_text_2")}</p>
                        </div>
                    </div>
                    <div className="icon">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/households_3.png`} alt="" className="icon_image" />
                        <div className="icon_title heading">
                            <h4>{t("elsupply.households.icon_title_3")}</h4>
                        </div>
                        <div className="icon_text">
                            <p>{t("elsupply.households.icon_text_3")}</p>
                        </div>
                    </div>
                    <div className="icon">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/households_4.png`} alt="" className="icon_image" />
                        <div className="icon_title heading">
                            <h4>{t("elsupply.households.icon_title_4")}</h4>
                        </div>
                        <div className="icon_text">
                            <p>{t("elsupply.households.icon_text_4")}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container content">
                <div className="heading mb-5">
                    <h4>{t("elsupply.households.subtitle_2")}</h4>
                    <h3>{t("elsupply.households.title_2")}</h3>
                </div>
                <div className="text">
                    <p>{t("elsupply.households.text_3")}</p>
                </div>
                <div className="boxes">
                    <div className="box greybg">
                        <img src={`${process.env.PUBLIC_URL}/images/products/box_image_1.png`} alt="" className="box_image" />
                        <div className="box_title heading">
                            <h4>{t("elsupply.households.box_title_1")}</h4>
                        </div>
                        <div className="box_text">
                            {t("elsupply.households.box_text_1")}
                        </div>
                    </div>
                    <div className="box small_boxes">
                        <div className="small_box greybg">
                            <img src={`${process.env.PUBLIC_URL}/images/products/box_image_2.png`} alt="" className="box_image" />
                            <div className="box_title heading">
                                <h4>{t("elsupply.households.box_title_2")}</h4>
                                {t("elsupply.households.box_text_2")}
                            </div>
                        </div>
                        <div className="small_box greybg">
                            <img src={`${process.env.PUBLIC_URL}/images/products/box_image_3.png`} alt="" className="box_image" />
                            <div className="box_title heading">
                                <h4>{t("elsupply.households.box_title_3")}</h4>
                                {t("elsupply.households.box_text_3")}
                            </div>
                        </div>
                        <div className="small_box greybg">
                            <img src={`${process.env.PUBLIC_URL}/images/products/box_image_4.png`} alt="" className="box_image" />
                            <div className="box_title heading">
                                <h4>{t("elsupply.households.box_title_4")}</h4>
                                {t("elsupply.households.box_text_4")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container content">
                <div className="heading mb-5">
                    <h3>{t("elsupply.households.title_3")}</h3>
                </div>
                <div className="functions">
                    <div className="function">
                        <div className="function_number"><span>1</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.households.function_title_1")}</h4>
                            {t("elsupply.households.function_text_1")}</div>
                    </div>
                    <div className="function">
                        <div className="function_number"><span>2</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.households.function_title_2")}</h4>
                            {t("elsupply.households.function_text_2")}</div>
                    </div>
                    <div className="function">
                        <div className="function_number"><span>3</span></div>
                        <div className="function_title heading">
                            <h4>{t("elsupply.households.function_title_3")}</h4>
                            {t("elsupply.households.function_text_3")}</div>
                    </div>
                </div>
            </div>

            <div className="container content">
                <div className="heading mb-3 text-start">
                    <h3>{t("elsupply.households.sm_title")}</h3>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-7 col-lg-9">
                        <div className="text">
                            {t("elsupply.households.sm_text_1")}
                            <div className="meter_box greybg">
                                <div className="box_content">
                                    {t("elsupply.households.sm_text_2")}
                                    <div className="box_row">
                                        <a href="https://apps.apple.com/si/app/ngen-energy/id6448929330" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/images/appstore.png`} alt="" /></a>
                                        <a href="https://play.google.com/store/apps/details?id=ngen.mobile.app" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/images/googleplay.png`} alt="" /></a>
                                    </div>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/images/products/sg_logo.png`} alt="" className="sglogo" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-3">
                        <img src={`${process.env.PUBLIC_URL}/images/products/smart_meter.png`} alt="" className="smart_meter" />
                    </div>
                </div>
            </div>

            <div className="container content change">
                <div className="card">
                    <img src={`${process.env.PUBLIC_URL}/images/cards/change_bg.png`} alt="Gospodinjstva" />
                    <div className="card-text">
                        <h3>{t("elsupply.households.zd_title")}</h3>
                        <p>{t("elsupply.households.zd_text_1")}</p>
                        <br />
                        <a href={t('elsupply.url_households')} exact class="btn btn-secondary">{t('elsupply.households.zd_button_1')}</a>
                        <a href={t('elsupply.url_companies')} exact class="btn btn-secondary">{t('elsupply.households.zd_button_2')}</a>
                    </div>
                </div>
            </div>

            <div className="container content files">
                <div className="heading mb-5">
                    <h4>{t("elsupply.pricelist_subtitle")}</h4>
                    <h3>{t("elsupply.pricelist_title")}</h3>
                </div>
                <div className="row">
                    <div className="col-lg-6 files_holder files-left">
                        <div className="file">
                            <p><strong>{t('elsupply.pricelist_1_title')}</strong><br /><small>{t('elsupply.pricelist_1_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-gospodinjski-odjemalci" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                        <div className="file">
                            <p><strong>{t('elsupply.pricelist_2_title')}</strong><br /><small>{t('elsupply.pricelist_2_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-mali-poslovni-odjemalci" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                        {/* <div className="file">
                            <p><strong>{t('elsupply.pricelist_2_title')}</strong><br /><small>{t('elsupply.pricelist_2_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-ee-go" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                        <div className="file">
                            <p><strong>{t('elsupply.pricelist_7_title')}</strong><br /><small>{t('elsupply.pricelist_7_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-nova-doba-samooskrbe" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                        <div className="file">
                            <p><strong>{t('elsupply.pricelist_10_title')}</strong><br /><small>{t('elsupply.pricelist_10_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-ee-smpo-nds" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                        <div className="file">
                            <p><strong>{t('elsupply.pricelist_3_title')}</strong><br /><small>{t('elsupply.pricelist_3_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-ngen-ps" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div> */}
                    </div>
                    <div className="col-lg-6 files_holder files-right">
                        <div className="file">
                            <p><strong>{t('elsupply.pricelist_3_title')}</strong><br /><small>{t('elsupply.pricelist_3_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-storitev" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                        {/* <div className="file">
                            <p><strong>{t('elsupply.pricelist_5_title')}</strong><br /><small>{t('elsupply.pricelist_5_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-ee-mpo" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                        <div className="file">
                            <p><strong>{t('elsupply.pricelist_6_title')}</strong><br /><small>{t('elsupply.pricelist_6_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-dodatne-storitve" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                        <div className="file">
                            <p><strong>{t('elsupply.pricelist_11_title')}</strong><br /><small>{t('elsupply.pricelist_11_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-ee-smpo-mo" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                        <div className="file">
                            <p><strong>{t('elsupply.pricelist_8_title')}</strong><br /><small>{t('elsupply.pricelist_8_text')}</small></p>
                            <a href="https://qr.ngen.si/cenik-samofakturiranje" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="container content files">
                <div className="heading mb-5">
                    <h3>{t("elsupply.pogoji_title")}</h3>
                </div>
                <div className="row">
                    <div className="col-lg-6 files_holder files-left">
                        <div className="file">
                            <p><strong>{t('elsupply.pogoji_10_title')}</strong><br /><small>{t('elsupply.pogoji_10_text')}</small></p>
                            <a href="https://qr.ngen.si/sp-dee-go" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                    </div>
                    <div className="col-lg-6 files_holder files-right">
                    <div className="file">
                            <p><strong>{t('elsupply.pogoji_11_title')}</strong><br /><small>{t('elsupply.pogoji_11_text')}</small></p>
                            <a href="https://qr.ngen.si/sp-dee-mpo" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/icons/download.png`} alt="icon download" /></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container content files">
                <div className="heading mb-5">
                    <h3>{t("elsupply.faq_title")}</h3>
                </div>
                <div class="accordion accordion-flush" id="faqAccordion">
                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q1">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q1-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.households.q1")}
                            </button>
                        </h4>
                        <div id="q1-collapse" class="accordion-collapse collapse" aria-labelledby="q1" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.households.a1") }}></p></div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q2">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q2-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.households.q2")}
                            </button>
                        </h4>
                        <div id="q2-collapse" class="accordion-collapse collapse" aria-labelledby="q2" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.households.a2") }}></p></div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q3">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q3-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.households.q3")}
                            </button>
                        </h4>
                        <div id="q3-collapse" class="accordion-collapse collapse" aria-labelledby="q3" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.households.a3") }}></p></div>
                        </div>
                    </div>

                    {/* <div class="accordion-item">
                        <h4 class="accordion-header" id="q4">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q4-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.households.q4")}
                            </button>
                        </h4>
                        <div id="q4-collapse" class="accordion-collapse collapse" aria-labelledby="q4" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.households.a4") }}></p></div>
                        </div>
                    </div> */}

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q5">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q5-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.households.q5")}
                            </button>
                        </h4>
                        <div id="q5-collapse" class="accordion-collapse collapse" aria-labelledby="q5" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.households.a5") }}></p></div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q6">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q6-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.households.q6")}
                            </button>
                        </h4>
                        <div id="q6-collapse" class="accordion-collapse collapse" aria-labelledby="q6" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.households.a6") }}></p></div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h4 class="accordion-header" id="q7">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q7-collapse" aria-expanded="false" aria-controls="flush-collapseOne">
                                {t("elsupply.households.q7")}
                            </button>
                        </h4>
                        <div id="q7-collapse" class="accordion-collapse collapse" aria-labelledby="q7" data-bs-parent="#faqAccordion">
                            <div class="accordion-body"><p dangerouslySetInnerHTML={{ __html: t("elsupply.households.a7") }}></p></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SupplyHouseholds;
