import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookies } from "react-cookie";
import './Navbar.css';
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {

    const [cookies, setCookie] = useCookies(["langMenu"]);

    const { t, i18n } = useTranslation();
    const [currentLanguage, setLanguage] = useState('en_si');
    const changeLanguage = value => {
        i18n
            .changeLanguage(value)
            .then(() => setLanguage(value) && console.log(value))
            .catch(err => console.log(err));
    };

    const [navbarOpen, setNavbarOpen] = useState(false)
    const handleToggle = () => { setNavbarOpen(!navbarOpen) }
    const [subMenuOpen, setSubMenuOpen] = useState(false)
    const handleToggleSub = () => { setSubMenuOpen(!subMenuOpen) }
    const closeMenu = () => { setNavbarOpen(false); setSubMenuOpen(false); }

    const [langBarOpen, setLangBarOpen] = useState(false)
    const handlelangToggle = () => { setLangBarOpen(!langBarOpen) }

    useEffect(() => {
        if (!cookies.langMenu) {
            setLangBarOpen(true);
            setCookie("langMenu", "1", {
                path: "/"
            });
        }
    }, []);

    const closeLangMenu = () => { setLangBarOpen(false); }

    const tween = useRef(null);
    const menuBox = useRef(null);
    const tweenL = useRef(null);
    const langMenuBox = useRef(null);
    const tweenvideo = useRef(null);
    const videoBlur = useRef(null);

    useEffect(() => {
        tween.current = gsap.timeline().fromTo(
            menuBox.current,
            {
                x: "100%",
            },
            {
                duration: 1,
                x: "0%",
                ease: "power2"
            }, 0.25);
    }, []);
    useEffect(() => {
        if (!navbarOpen) {
            tween.current.reverse();
        } else {
            tween.current.play();
            setLangBarOpen(false);
        }
    }, [navbarOpen]);

    useEffect(() => {
        tweenvideo.current = gsap.timeline().fromTo(
            videoBlur.current,
            {
                filter: "blur(0px)",
            },
            {
                duration: 0.5,
                filter: "blur(30px)",
                ease: "power2"
            }, 0);

    }, []);
    useEffect(() => {
        if (!subMenuOpen) {
            tweenvideo.current.reverse();
        } else {
            tweenvideo.current.play();
        }
    }, [subMenuOpen]);

    if (navbarOpen == true) {
        var body = document.body;
        body.classList.add("disable_scroll");
    } else {
        var body = document.body;
        body.classList.remove("disable_scroll");
    }
    const location = useLocation();
    const [visibility, setVisibility] = useState("visible");
    useEffect(() => {
        if (
            pagelocation === "/" ||
            pagelocation === "/sl_si" ||
            pagelocation === "/en_si" ||
            pagelocation === "/en_at" ||
            pagelocation === "/de_at" ||
            pagelocation === "/en_de" ||
            pagelocation === "/de_de" ||
            pagelocation === "/en_pl" ||
            pagelocation === "/pl_pl" ||
            pagelocation === "/en_es" ||
            pagelocation === "/es_es" ||
            pagelocation === "/pt_pt" ||
            pagelocation === "/en_pt" ||
            pagelocation === "/hr_hr" ||
            pagelocation === "/en_gb"
        ) {
            setVisibility("visible");
        } else {
            setVisibility("visible");
        }
    }, [location.pathname]);

    useEffect(() => {
        tweenL.current = gsap.timeline().fromTo(
            langMenuBox.current,
            {
                x: "100%",
            },
            {
                duration: 0.5,
                x: "0%",
                ease: "power2"
            }, 0.25);
    }, []);
    useEffect(() => {
        if (!langBarOpen) {
            tweenL.current.reverse();
        } else {
            tweenL.current.play();
            setNavbarOpen(false);
        }
    }, [langBarOpen]);

    var pagelocation = window.location.pathname;
    return (
        <>
            <nav className="navbar">
                <div className="container">
                    <div className="row">
                        <div className={`col d-flex flex-row
                        ${pagelocation == '/' ||
                                pagelocation == '/sl_si' ||
                                pagelocation == '/en_si' ||                                
                                pagelocation == '/en_at' ||
                                pagelocation == '/de_at' ||
                                pagelocation == '/en_de' ||
                                pagelocation == '/de_de' ||
                                pagelocation == '/en_pl' ||
                                pagelocation == '/pl_pl' ||
                                pagelocation == '/en_es' ||
                                pagelocation == '/es_es' ||
                                pagelocation == '/pt_pt' ||
                                pagelocation == '/en_pt' ||
                                pagelocation == '/hr_hr' ||
                                pagelocation == '/en_gb' 
                                ? "justify-content-evenly" : "justify-content-between"}`}>

                            <Link to={t('url.home')} className={`navbar-logo `}><img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="nGen logo" width="150px;" /></Link>

                            {/* {i18n.language === 'sl_si' && (
                                <a href={'//star.ngen.si/sl'} target="_blank" className={`${pagelocation == '/' || pagelocation == '/sl_si' ? "d-inline d-lg-none" : "d-none"}`}><img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Ngen star logo" width="150px" /></a>
                            )}
                            {i18n.language === 'en_si' && (
                                <a href={'//star.ngen.si/en'} target="_blank" className={`${pagelocation == '/' || pagelocation == '/en_si' ? "d-inline d-lg-none" : "d-none"}`}><img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Ngen star logo" width="150px" /></a>
                            )} */}
                            {/* {currentLanguage.includes('_si') || currentLanguage.includes('_at') ? (
                                <Link to={t('url.elsupply')} className={`link additional d-none d-xl-block ${visibility}`} activeClassName="active" exact>{t('pages.elsupply')}</Link>
                            ) : (
                                <HashLink to={t('url.gospodinjstva') + '#doee'} className={`link additional d-none d-xl-block ${visibility}`}>{t('pages.elsupply')}</HashLink>
                            )} */}
                            {/* <HashLink to={t('url.gospodinjstva') + '#bess'} className={`link additional d-none d-xl-block ${visibility}`}>{t('pages.hranilniki')}</HashLink> */}
                            {/* {i18n.language === 'sl_si' && (
                            <Link to={t('url.elsupply')} className={`link additional d-none d-xl-block ${visibility}`} activeClassName="active" exact>{t('pages.elsupply')}</Link>
                            )} */}
                            <a href={t('url.sgconnect')} target='_blank' className={`link additional d-none d-xl-block ${visibility}`}>{t('pages.sgconnect')}</a>
                            <a href="https://justcharge.io" target='_blank' className={`link additional d-none d-xl-block ${visibility}`}>JustCharge</a>
                            {i18n.language === 'sl_si' && (
                                <Link to={t('url.elsupply')} className={`link additional d-none d-xl-block ${visibility}`} activeClassName="active" exact>{t('pages.elsupply')}</Link> 
                            )}
                            {/* {i18n.language === 'de_at' && (
                                <Link to={t('url.elsupply')} className={`link additional d-none d-xl-block ${visibility}`} activeClassName="active" exact>{t('pages.elsupply')}</Link> 
                            )} */}
                        </div>
                        <div className="col menu-toggle-wrapper">
                            {/* {i18n.language === 'sl_si' && (
                                <a href={'//star.ngen.si/'} target="_blank" className="btn-primary star-btn d-none d-lg-inline">{t('home.visit')} <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Ngen star logo" width="150px" /></a>
                            )}
                            {i18n.language === 'en_si' && (
                                <a href={'//star.ngen.si/en'} target="_blank" className="btn-primary star-btn d-none d-lg-inline">{t('home.visit')} <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Ngen star logo" width="150px" /></a>
                            )} 
                            {i18n.language === 'de_at' && (
                                <a href={'//star.ngen-group.eu/at'} target="_blank" className="btn-primary star-btn d-none d-lg-inline">{t('home.visit')} <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Ngen star logo" width="150px" /></a>
                            )}
                            {i18n.language === 'en_at' && (
                                <a href={'//star.ngen-group.eu/at'} target="_blank" className="btn-primary star-btn d-none d-lg-inline">{t('home.visit')} <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Ngen star logo" width="150px" /></a>
                            )}*/}
                            <button className={`menutoogle ${navbarOpen ? "cross" : "hamburger"}`} onClick={handleToggle}>
                                {navbarOpen ? (
                                    <img src={`${process.env.PUBLIC_URL}/images/cross.svg`} alt="menu close icon" />
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/images/hamburger.svg`} alt="menu open icon" />
                                )}
                            </button>

                            <button className={`langMenuToogle ${langBarOpen ? "langMenuToogleHide" : "langMenuToogle"}`} onClick={handlelangToggle}>
                                {langBarOpen ? (
                                    <img src={`${process.env.PUBLIC_URL}/images/globe.svg`} alt="menu close icon" />
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/images/globe.svg`} alt="menu open icon" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            <div className={`menu ${navbarOpen ? "showMenu" : "hideMenu"}`} ref={menuBox}>
                <video loop autoPlay={`${navbarOpen ? "true" : "false"}`} muted playsInline poster={`${process.env.PUBLIC_URL}/images/menu-poster.png`} className={`bgvideo ${navbarOpen ? "showVideo" : "hideVideo"} ${subMenuOpen ? "blurred" : ""}`} ref={videoBlur} >
                    <source src={`${process.env.PUBLIC_URL}/videos/menu.mp4`} type="video/mp4" />
                </video>
                <ul className="nav-items">
                    <li className="nav-item">
                        <Link to={t('url.home')} className="link" activeClassName="active" onClick={() => closeMenu()} exact>{t('pages.home')}</Link>
                    </li>
                    <li className="nav-item">
                        <NavLink to={t('url.whoweare')} className="link" activeClassName="active" onClick={() => closeMenu()} exact>{t('pages.whoweare')}</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={t('url.whatwedo')} className="link" activeClassName="active" onClick={() => closeMenu()} exact>{t('pages.whatwedo')}</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="link" to={t('url.products')} onMouseEnter={handleToggleSub} onClick={() => closeMenu()} exact>{t('pages.products')}<i className={`fa ${subMenuOpen ? "fa-chevron-right move" : "fa-chevron-right"}`}></i></NavLink>
                        <ul className={`subMenu ${subMenuOpen ? "showSubMenu" : "hideSubMenu"}`}>
                            <li className="nav-item">
                                <NavLink to={t('url.tso')} className="link" activeClassName="active" onClick={() => closeMenu()} exact>{t('pages.tso')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={t('url.podjetja')} className="link" activeClassName="active" onClick={() => closeMenu()} exact>{t('pages.podjetja')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={t('url.gospodinjstva')} className="link" activeClassName="active" onClick={() => closeMenu()} exact>{t('pages.gospodinjstva')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={t('url.poslovnipartnerji')} className="link" activeClassName="active" onClick={() => closeMenu()} exact>{t('pages.poslovnipartnerji')}</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <NavLink to={t('url.contact')} className="link" activeClassName="active" onClick={() => closeMenu()} exact>{t('pages.contact')}</NavLink>
                    </li>
                    <li className="nav-item">
                        <a href="https://ps.ngen.si/" className="link" activeClassName="active" onClick={() => closeMenu()} target="_blank" rel="noopener noreferrer">{t('pages.userportal')}</a>
                    </li>
                    {currentLanguage.includes('_si') && (
                        <li className="nav-item lang">
                            <button className={`langselector ${document.documentElement.lang === 'en_si' ? "active" : ""}`} key='en_si' type='submit' onClick={() => { changeLanguage('en_si'); closeMenu() }} >
                                <NavLink to={'en_si'}>ENG</NavLink>
                            </button>
                            <span>  /  </span>
                            <button className={`langselector ${document.documentElement.lang === 'sl_si' ? "active" : ""}`} key='sl_si' type='submit' onClick={() => { changeLanguage('sl_si'); closeMenu() }} >
                                <NavLink to={'sl_si'}>SLO</NavLink>
                            </button>
                        </li>
                    )}
                    {currentLanguage.includes('_at') && (
                        <li className="nav-item lang">
                            <button className={`langselector ${document.documentElement.lang === 'en_at' ? "active" : ""}`} key='en_at' type='submit' onClick={() => { changeLanguage('en_at'); closeMenu() }} >
                                <NavLink to={'en_at'}>ENG</NavLink>
                            </button>
                            <span>  /  </span>
                            <button className={`langselector ${document.documentElement.lang === 'de_at' ? "active" : ""}`} key='de_at' type='submit' onClick={() => { changeLanguage('de_at'); closeMenu() }} >
                                <NavLink to={'de_at'}>DE</NavLink>
                            </button>
                        </li>
                    )}
                    {currentLanguage.includes('_de') && (
                        <li className="nav-item lang">
                            <button className={`langselector ${document.documentElement.lang === 'en_de' ? "active" : ""}`} key='en_de' type='submit' onClick={() => { changeLanguage('en_de'); closeMenu() }} >
                                <NavLink to={'en_de'}>ENG</NavLink>
                            </button>
                            <span>  /  </span>
                            <button className={`langselector ${document.documentElement.lang === 'de_de' ? "active" : ""}`} key='de_de' type='submit' onClick={() => { changeLanguage('de_de'); closeMenu() }} >
                                <NavLink to={'de_de'}>DE</NavLink>
                            </button>
                        </li>
                    )}
                    {currentLanguage.includes('_pt') && (
                        <li className="nav-item lang">
                            <button className={`langselector ${document.documentElement.lang === 'en_pt' ? "active" : ""}`} key='en_pt' type='submit' onClick={() => { changeLanguage('en_pt'); closeMenu() }} >
                                <NavLink to={'en_pt'}>ENG</NavLink>
                            </button>
                            <span>  /  </span>
                            <button className={`langselector ${document.documentElement.lang === 'pt_pt' ? "active" : ""}`} key='pt_pt' type='submit' onClick={() => { changeLanguage('pt_pt'); closeMenu() }} >
                                <NavLink to={'pt_pt'}>PRT</NavLink>
                            </button>
                        </li>
                    )}

                    {currentLanguage.includes('_pl') && (
                        <li className="nav-item lang">
                            <button className={`langselector ${document.documentElement.lang === 'en_pl' ? "active" : ""}`} key='en_pl' type='submit' onClick={() => { changeLanguage('en_pl'); closeMenu() }} >
                                <NavLink to={'en_pl'}>ENG</NavLink>
                            </button>
                            <span>  /  </span>
                            <button className={`langselector ${document.documentElement.lang === 'pl_pl' ? "active" : ""}`} key='pl_pl' type='submit' onClick={() => { changeLanguage('pl_pl'); closeMenu() }} >
                                <NavLink to={'pl_pl'}>POL</NavLink>
                            </button>
                        </li>
                    )}

                    {currentLanguage.includes('_es') && (
                        <li className="nav-item lang">
                            <button className={`langselector ${document.documentElement.lang === 'en_es' ? "active" : ""}`} key='en_es' type='submit' onClick={() => { changeLanguage('en_es'); closeMenu() }} >
                                <NavLink to={'en_es'}>ENG</NavLink>
                            </button>
                            <span>  /  </span>
                            <button className={`langselector ${document.documentElement.lang === 'es_es' ? "active" : ""}`} key='es_es' type='submit' onClick={() => { changeLanguage('es_es'); closeMenu() }} >
                                <NavLink to={'es_es'}>ESP</NavLink>
                            </button>
                        </li>
                    )}

                    {currentLanguage.includes('_hr') && (
                        <li className="nav-item lang">
                            <button className={`langselector ${document.documentElement.lang === 'hr_hr' ? "active" : ""}`} key='hr_hr' type='submit' onClick={() => { changeLanguage('hr_hr'); closeMenu() }} >
                                <NavLink to={'hr_hr'}>HRV</NavLink>
                            </button>
                        </li>
                    )}

                    {currentLanguage.includes('_gb') && (
                        <li className="nav-item lang">
                            <button className={`langselector ${document.documentElement.lang === 'en_gb' ? "active" : ""}`} key='en_gb' type='submit' onClick={() => { changeLanguage('en_gb'); closeMenu() }} >
                                <NavLink to={'en_gb'}>GB</NavLink>
                            </button>
                        </li>
                    )}

                </ul>
            </div>

            <div className={`langMenu ${langBarOpen ? "showLangMenu" : "hideLangMenu"}`} ref={langMenuBox}>
                <button className={`langMenuClose`} onClick={handlelangToggle}>
                    <img src={`${process.env.PUBLIC_URL}/images/cross.svg`} alt="menu close icon" />
                </button>

                {/* Austria */}
                <div className="row">
                    <div className="col-sm-12 country_name">Austria <img src="https://flagcdn.com/256x192/at.png" width="28" height="21" alt="Austria"></img></div>
                    <div className="col-md-6"><NavLink to={'de_at'} className="link" activeClassName="active" onClick={() => { changeLanguage('de_at'); closeLangMenu() }}>Deutsch</NavLink></div>
                    <div className="col-md-6"><NavLink to={'en_at'} className="link" activeClassName="active" onClick={() => { changeLanguage('en_at'); closeLangMenu() }}>English</NavLink></div>
                </div>                
                {/* Croatia */}
                <div className="row">
                    <div className="col-sm-12 country_name">Croatia <img src="https://flagcdn.com/256x192/hr.png" width="28" height="21" alt="Croatia"></img></div>
                    <div className="col-md-6"><NavLink to={'hr_hr'} className="link" activeClassName="active" onClick={() => { changeLanguage('hr_hr'); closeLangMenu() }}>Hrvatski</NavLink></div>
                </div>
                {/* Germany */}
                <div className="row">
                    <div className="col-sm-12 country_name">Germany <img src="https://flagcdn.com/256x192/de.png" width="28" height="21" alt="Germany"></img></div>
                    <div className="col-md-6"><NavLink to={'de_de'} className="link" activeClassName="active" onClick={() => { changeLanguage('de_de'); closeLangMenu() }}>Deutsch</NavLink></div>
                    <div className="col-md-6"><NavLink to={'en_de'} className="link" activeClassName="active" onClick={() => { changeLanguage('en_de'); closeLangMenu() }}>English</NavLink></div>
                </div>
                {/* Great Britain */}
                <div className="row">
                    <div className="col-sm-12 country_name">Great Britain <img src="https://flagcdn.com/256x192/gb.png" width="28" height="21" alt="Great Britain"></img></div>
                    <div className="col-md-6"><NavLink to={'en_gb'} className="link" activeClassName="active" onClick={() => { changeLanguage('en_gb'); closeLangMenu() }}>English</NavLink></div>
                </div>
                {/* Portugal */}
                <div className="row">
                    <div className="col-sm-12 country_name">Portugal <img src="https://flagcdn.com/256x192/pt.png" width="28" height="21" alt="Portugal"></img></div>
                    <div className="col-md-6"><NavLink to={'pt_pt'} className="link" activeClassName="active" onClick={() => { changeLanguage('pt_pt'); closeLangMenu() }}>Português</NavLink></div>
                    <div className="col-md-6"><NavLink to={'en_pt'} className="link" activeClassName="active" onClick={() => { changeLanguage('en_pt'); closeLangMenu() }}>English</NavLink></div>
                </div>
                {/* Poland */}
                <div className="row">
                    <div className="col-sm-12 country_name">Poland <img src="https://flagcdn.com/256x192/pl.png" width="28" height="21" alt="Poland"></img></div>
                    <div className="col-md-6"><NavLink to={'pl_pl'} className="link" activeClassName="active" onClick={() => { changeLanguage('pl_pl'); closeLangMenu() }}>Polski</NavLink></div>
                    <div className="col-md-6"><NavLink to={'en_pl'} className="link" activeClassName="active" onClick={() => { changeLanguage('en_pl'); closeLangMenu() }}>English</NavLink></div>
                </div>
                {/* Slovenia */}
                <div className="row">
                    <div className="col-sm-12 country_name">Slovenia <img src="https://flagcdn.com/256x192/si.png" width="28" height="21" alt="Slovenia"></img></div>
                    <div className="col-md-6"><NavLink to={'sl_si'} className="link" activeClassName="active" onClick={() => { changeLanguage('sl_si'); closeLangMenu() }}>Slovenščina</NavLink></div>
                    <div className="col-md-6"><NavLink to={'en_si'} className="link" activeClassName="active" onClick={() => { changeLanguage('en_si'); closeLangMenu() }}>English</NavLink></div>
                </div>
                {/* Spain */}
                <div className="row">
                    <div className="col-sm-12 country_name">Spain <img src="https://flagcdn.com/256x192/es.png" width="28" height="21" alt="Spain"></img></div>
                    <div className="col-md-6"><NavLink to={'es_es'} className="link" activeClassName="active" onClick={() => { changeLanguage('es_es'); closeLangMenu() }}>Español</NavLink></div>
                    <div className="col-md-6"><NavLink to={'en_es'} className="link" activeClassName="active" onClick={() => { changeLanguage('en_es'); closeLangMenu() }}>English</NavLink></div>
                </div>
            </div>
        </>
    )
}

export default Navbar