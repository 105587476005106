import React from 'react';
import './TSO.css';
import { useTranslation } from 'react-i18next';
import VideoLower from './Elements/VideoLower';
import Cardcompanies from './Elements/Cardcompanies';
import Cardhouseholds from './Elements/Cardhouseholds';
import Cardpartners from './Elements/Cardpartners';
import i18n from 'i18next';

const TSO = () => {
  var lang = i18n.language;
  const { t } = useTranslation();

  document.title = t("page-title.tso");

  return (
    <div className="UITso">
      <div className="container-fluid background">
        <div className="bg-image">
          <VideoLower />
        </div>
        <div className="container">
          <div className="heading">
            <h2>{t('tso.ngenponudba')}</h2>
            <h1>{t('tso.tso')}</h1>
          </div>
        </div>
      </div>
      <div className="container hero-image">
        <img src={`${process.env.PUBLIC_URL}/images/products/tso.png`} alt="" className='starter-whatwedo' />
      </div>
      <div className="overlay">
        <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tr.svg`} alt="" />
      </div>

      {lang.includes('sl_') && (
         <div className="container content">
         <div className="text">
           <h4>{t('tso.heading1')}</h4>
           <ul>
             <li><strong>{t('tso.text1')}</strong></li>
             <p>{t('tso.text2')}</p>
             <li><strong>{t('tso.text3')}</strong></li>
             <p>{t('tso.text4')}</p>
           </ul>
         </div>
       </div>)}

      {!lang.includes('sl_') && (
      <div className="container content">
        <div className="text">
          {lang.includes('de_at') && (
            <h4 className={lang.includes('de_') ? 'smaller-title' : ''}>{t('tso.heading3')}</h4>
          )}
          <p>{t('tso.text1')}{t('tso.text2')}</p>
          <h4 className={lang.includes('de_') ? 'smaller-title' : ''}>{t('tso.heading1')}</h4>          
          <p>{t('tso.text3')}<br/>{t('tso.text4')}</p>
          <h4 className={lang.includes('de_') ? 'smaller-title' : ''}>{t('tso.heading2')}</h4>
          <p>{t('tso.text5')}</p> 
        </div>
      </div>)}   
       
      <div className="container cards">        
          <div className="heading">
            <h4>{t('tso.lookat')}</h4>
            <h3 className={lang.includes('de_') ? 'smallish-title' : ''}>{t('tso.other-products')}</h3>
          </div>
        <div className="row cards">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <Cardhouseholds />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <Cardcompanies />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <Cardpartners />
          </div>
        </div>
      </div>
    </div >
  )
}

export default TSO