import React, { useState, useEffect } from "react";
import "./JobDescription.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useParams } from "react-router-dom";

function JobDescription() {
  const { index } = useParams();
  const [job, setJob] = useState(null);
  const { t } = useTranslation();
  var lang = i18n.language;

  document.title = t("page-title.jobdescription");

  var file;
  if (lang.includes("sl_si")) {
   file = "jobs-sl_si.json";
  } else if (lang.includes("en_si")) {
    file = "jobs-en_si.json";
  } else if (lang.includes("de_at")) {
    file = "jobs-de_at.json";
  } else if (lang.includes("en_at")) {
    file = "jobs-en_at.json";
  } else if (lang.includes("de_de")) {
    file = "jobs-de_de.json";
  } else if (lang.includes("en_de")) {
    file = "jobs-en_de.json";
  } else if (lang.includes("_pt")) {
    file = "jobs-en_pt.json";
  }
  useEffect(() => {    
    const fetchJobDescription = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/api/${file}`);
        const data = await response.json();
        if (data.jobs && data.jobs[index]) {
          // Check if the job at the specified index exists
          const selectedJob = data.jobs[index];
          setJob(selectedJob);
        } else {
          console.error(`Job at index ${index} not found.`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchJobDescription();
  }, [index]);

  if (!job) {
    // Handle the case where the job data is still loading
    return <div>Loading...</div>;
  }

  return (
    <div className="UIJobDescription">
      <div className="container-fluid background">
        <div className="container">
          <div className="row">
            <div className="heading">
              <h1>{t("job-description.title")}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container content">
        <div className="text">
          <h1 className="text-center mb-5 job-title">{job.title}</h1>
          <p>{job.description}</p>

          <h4>{t("job-description.education")}</h4>
          <ul>
            {job.education.map((education, index) => (
              <li key={index}>{education}</li>
            ))}
          </ul>

          <h4>{t("job-description.experiences")}</h4>
          <ul>
            {job.experiences.map((experience, index) => (
              <li key={index}>{experience}</li>
            ))}
          </ul>

          <h4>{t("job-description.job-tasks")}</h4>
          <ul>
            {job.jobTasks.map((task, index) => (
              <li key={index}>{task}</li>
            ))}
          </ul>

          <h4>{t("job-description.we-offer")}</h4>
          <ul>
            {job.weOffer.map((offer, index) => (
              <li key={index}>{offer}</li>
            ))}
          </ul>
          <p className="mt-5">
            {job.contact}
            <a
              href={`mailto:${job.mail}`}              
            >
              {job.mail}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default JobDescription;
